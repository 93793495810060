<template>
  <div class="view">
    <router-view
      name="monetization_bundle_content"
      ref="monetization-bundle-content"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
// import {  } from "@/assets/icons";
library.add(faLink, faTimes, faUpload);

export default {
  name: "Main",
  computed: {
    ...mapState("helper", ["features"]),

    ...mapGetters("helper", ["labels"]),

    saveBtnDisabled() {
      return this.$store.getters["monetizationBundle/countChanges"]
        .saveBtnDisabled;
    },
    localMBData() {
      return this.$store.state.monetizationBundle.localMBData;
    },
    pageHeaderData() {
      let btnList = [
        {
          type: "label",
          link: { name: "mb-settings-basic" },
          label: this.labels.basic,
          class: `btn-type-label ${
            this.$route.name === "mb-settings-basic" ? "current" : ""
          }`,
        },
        // {
        // 	type: 'label',
        // 	link: { name: 'mb-settings-pricing' },
        // 	label: this.labels.pricing,
        // 	class: `btn-type-label ${ this.$route.name === 'mb-settings-pricing' ? 'current' : '' }`
        // },
        {
          type: "label",
          link: { name: "mb-settings-advanced", params: { kind: "content" } },
          label: this.labels.content,
          class: `btn-type-label ${this.checkIfActiveBtn("content")}`,
        },
      ];

      if (this.features.payable)
        btnList.push(
          ...[
            {
              type: "label",
              link: {
                name: "mb-settings-advanced",
                params: { kind: "banner" },
              },
              label: this.labels.banner,
              class: `btn-type-label ${this.checkIfActiveBtn("banner")}`,
            },
            {
              type: "label",
              link: {
                name: "mb-settings-advanced",
                params: { kind: "overview" },
              },
              label: this.labels.overview,
              class: `btn-type-label ${this.checkIfActiveBtn("overview")}`,
            },
            {
              type: "label",
              link: {
                name: "mb-settings-advanced",
                params: { kind: "checkout" },
              },
              label: this.labels.checkout,
              class: `btn-type-label ${this.checkIfActiveBtn("checkout")}`,
            },
          ]
        );

      btnList.push(
        ...[
          {
            type: "label",
            link: { name: "monetization-bundle-analytics" },
            label: this.labels.analytics,
            class: `btn-type-label ${
              this.$route.name === "monetization-bundle-analytics"
                ? "current"
                : ""
            }`,
          },
          {
            type: "button",
            label: this.labels.save,
            variant: "btn-green",
            class: "btn-type-button",
            action: this.saveAction,
            disabled: this.saveBtnDisabled,
          },
        ]
      );

      const output = {
        preTitle: this.labels.monetization_bundle_title,
        title: this.localMBData.title,
        btnList: btnList,
      };

      return output;
    },
  },
  methods: {
    ...mapMutations("helper", {
      setPageHeaderData: "setPageHeaderData",
    }),
    saveAction() {
      this.$refs["monetization-bundle-content"].saveMBData();
    },
    checkIfActiveBtn(kind) {
      if (
        this.$route.name === "mb-settings-advanced" &&
        this.$route.params.kind === kind
      ) {
        return "current";
      }
      return "";
    },
  },
  beforeMount() {
    this.setPageHeaderData({ preTitle: "", title: "", btnList: [] });
  },
  mounted() {
    this.setPageHeaderData(this.pageHeaderData);
  },
  beforeDestroy() {
    this.setPageHeaderData({});
  },
  watch: {
    pageHeaderData(value) {
      this.setPageHeaderData(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
